var listingCount = 0;
function getNews(skipNumber) {
    //console.log("I am here ");
    var filter = $("#currentFilterNews").val();
    //console.log("You're filter: %o", filter);
    $.ajax({
        method: "GET",
        url: "/Umbraco/surface/News/GetNews",
        data: { skipNumber: skipNumber, filter: filter }
    }).done(function (res) {
        console.log(res);
        var count = res.newsArticles.length;
        listingCount = listingCount + count;
        //console.log("Listing count: %o", listingCount);
         


        var source = document.getElementById("news-template").innerHTML;
        var template = Handlebars.compile(source);
        var html = template(res.newsArticles);
        if (skipNumber == 0) {
            $("#newsListing").html(html);
        }
        else {
            var scroll = document.getElementById("scrollPoint");
            //console.log("SCroll : %o ", scroll);
            if (scroll != null) {
                document.getElementById("scrollPoint").remove();
            }
            $("#newsListing").append("<div id='scrollPoint'> </div>");
            var aTag = $("#scrollPoint");
            $('html,body').animate({ scrollTop: aTag.offset().top }, 'fast');
            $("#newsListing").append(html);
            
        }
       

        if (res.end == true || count == 0) {
            $("#loadMoreNews").hide();
        }
        else {
            $("#loadMoreNews").show();
        }


    });

}
function initNews() {
    //console.log("Init news");
    getNews(0);
}
$('#loadMoreNews').unbind('click').click(function () {
    // Get number of panels currently on page for the skip number
    //   alert("Click");
    // console.log("load more clicked");
    getNews(listingCount);

});
$('.filter-row__option--news').click(function () {
    //  alert("clicked");
    if ($(this).hasClass("filter-row__option--active")) {
        $(this).removeClass("filter-row__option--active");
        $("#currentFilterNews").val("None");
        listingCount = 0;
        getNews(0);
    }
    else {
        $(".filter-row__option--news").removeClass("filter-row__option--active");
        $(this).addClass("filter-row__option--active");
        var currentFilter = $(this).attr("data-val");
        $("#currentFilterNews").val(currentFilter);
        listingCount = 0;
        getNews(0);
    }

});

$('.filter-row__filter--news').click(function(){
    $('.filter-row__option').removeClass("filter-row__option--active");
    $("#currentFilterNews").val("None");
    listingCount = 0;
    getNews(0);
})