$(function(){
    menu_trigger = $('.nav-toggle');
    nav = $('.navbar');
    body = $('body');
    
    

    menu_trigger.click(function () {
        $(this).toggleClass('nav-toggle--active');
        nav.toggleClass('navbar--active');
        body.toggleClass('body--nav-open');
        if ($(this).attr('aria-expanded') === 'true' ) {
            $(this).attr('aria-expanded', 'false');
        } else {
            $(this).attr('aria-expanded', 'true');
        }
    });

    // $(document).click(function (e) {
    //     if (!$(menu_trigger).is(e.target)) {
    //         menu_trigger.removeClass('nav-toggle--active');
    //         nav.removeClass('navbar--active');
    //         body.removeClass('body--nav-open');
    //     }
    // });


    // Mobile dropdown

    // $('.nav__link').filter(function() {
    //     return ($(this).siblings('.nav__list--dropdown').length !== 0)
    // }).after('<span class="nav__dropdown-toggle">></span>');

    $('.nav__dropdown-toggle').on('click', function(){
        $(this).toggleClass('nav__dropdown-toggle--active');
        $(this).nextAll('.nav__dropdown').toggleClass('nav__dropdown--active');
    });
    $('.mobile__nav-title').on('click', function () {
        $(this).toggleClass('mobile__nav-title--active');
        $(this).nextAll('.nav__dropdown').toggleClass('nav__dropdown--active');
    });



})