$(function () {
    var controller = new ScrollMagic.Controller();
    // var controller = new ScrollMagic.Controller({
    //     container: ".nested-panel--scroll"
    // });

    $(".scroll-slide__slide").each(function () {
        var slide = $(this).data('id');
        var scene = new ScrollMagic.Scene({
                triggerElement: this,
                offset: 100,
                triggerHook: 0.7, 
                duration: $(this).outerHeight(true),
            })
             
            .setClassToggle(this, "scroll-slide__slide--active")
            .on('enter leave', function () {
                $('.scroll-slide__img').removeClass('scroll-slide__img--active');
                $('.scroll-slide__img#' + slide).addClass('scroll-slide__img--active');
            })
            .addTo(controller)
        //    .addIndicators()
        ;
    });

});


// $(function () {
//     var controller = new ScrollMagic.Controller();

//     $(".nested-panel--scroll").each(function () {
//         var slide = $(this).data('id');
//         var scene = new ScrollMagic.Scene({
//                 triggerElement: this,
//                 offset: 0,
//                 triggerHook: 0.0,
//                 duration: $(this).outerHeight(true),
//                 // duration: 500,
//                 reverse: true,
//                 pushFollowers: false
//             })

//             // .setClassToggle(this, "nested-panel--scroll--focus")
//             .on('enter leave', function () {

//             })
//             .on('start', function (event) {
//                 // console.log("Hit start point of scene.");
//                 $(".nested-panel--scroll").addClass('nested-panel--scroll--focus');
//                 $(".nested-panel--scroll").scrollLock('enable');
//                 $('body').addClass('overflow--hidden');
//             })
//             .on('end', function (event) {
//                 // console.log("Hit end point of scene.");
//                 $(".nested-panel--scroll").scrollLock('disable');
//             })
//             .on('start end', function (event) {

//             })
//             .addTo(controller)
//         // .addIndicators()
//         ;
//     });
// })

// $('.nested-panel--scroll').on('bottom.scrollLock', function (evt) {
//     $(this).scrollLock('disable');
//     $(".nested-panel--scroll").removeClass('nested-panel--scroll--focus');
//     $('body').removeClass('overflow--hidden');
// })

// $('.nested-panel--scroll').on('top.scrollLock', function (evt) {
//     $(this).scrollLock('disable');
//     $(".nested-panel--scroll").removeClass('nested-panel--scroll--focus');
//     $('body').removeClass('overflow--hidden');
// })
