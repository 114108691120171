var listingCount = 0;
function getCaseStudies(skipNumber) {
    //console.log("I am here ");
    var filter = $("#currentFilter").val();
    //console.log("You're filer: %o", filter);
    $.ajax({
        method: "GET",
        url: "/Umbraco/surface/CaseStudy/GetCaseStudies",
        data: { skipNumber: skipNumber, filter: filter }
    }).done(function (res) {
        //console.log(res);
        var count = res.caseStudies.length;
        listingCount = listingCount + count;
        //console.log("Listing count: %o", listingCount);

        //console.log("HERE in case studies");

        var source = document.getElementById("caseStudy-template").innerHTML;
        var template = Handlebars.compile(source);
        var html = template(res.caseStudies);
        if (skipNumber == 0) {
            $("#caseStudies").html(html);
        }
        else {
            var scroll = document.getElementById("scrollPoint");
            //console.log("SCroll : %o ", scroll);
            if (scroll != null) {
                document.getElementById("scrollPoint").remove();
            }
            $("#caseStudies").append("<div id='scrollPoint'> </div>");
            var aTag = $("#scrollPoint");
            $('html,body').animate({ scrollTop: aTag.offset().top }, 'fast');
            $("#caseStudies").append(html);
            
        }
       

        if (res.end == true || count == 0) {
            $("#loadMore").hide();
        }
        else {
            $("#loadMore").show();
        }


    });

}
function initCaseStudies() {
  
    getCaseStudies(0);
}
$('#loadMore').unbind('click').click(function () {
    // Get number of panels currently on page for the skip number
    //   alert("Click");
    // console.log("load more clicked");
    getCaseStudies(listingCount);

});

$('.filter-row__option').click(function () {
      //alert("clicked");
    if ($(this).hasClass("filter-row__option--active")) {
        $(this).removeClass("filter-row__option--active");
        $("#currentFilter").val("None");
        listingCount = 0;
        getCaseStudies(0);
    }
    else {
        $(".filter-row__option").removeClass("filter-row__option--active");
        $(this).addClass("filter-row__option--active");
        var currentFilter = $(this).attr("data-val");
        $("#currentFilter").val(currentFilter);
        listingCount = 0;
        getCaseStudies(0);
    }

});

$('.filter-row__filter--all').click(function(){
    $('.filter-row__option').removeClass("filter-row__option--active");
    $("#currentFilter").val("None");
    listingCount = 0;
    getCaseStudies(0);
})