$(function () {
    $('.filter-row__tag').click(function () {
        var filter_row = $('.filter-row__filters');

        $(this).toggleClass('filter-row__tag--active');
        filter_row.toggleClass('filter-row__filters--active');
    });

    $('.filter-row__title').click(function () {
        $(this).parent().toggleClass('filter-row__filter--active');
        $(this).next().toggleClass('filter-row__dropdown--active');

    });
    //$('.filter-row__filter').click(function () {
    //    $(this).toggleClass('filter-row__filter--active');
    //    var child = $(this).children().eq(1);
    //    console.log("Child: %o", child);
    //   // child.toggleClass('filter-row__dropdown--active');
    //    if (child.hasClass("filter-row__dropdown--active")) {
    //        child.removeClass("filter-row__dropdown--active");
    //    }
    //    else{
    //        child.addClass("filter-row__dropdown--active");
    //    }
    //});
});
