$(document).ready(function () {

    // Override the umbraco forms version
    $(".umbraco-forms-form.newsletterpopup input[type=submit]").not(".cancel").unbind('click');



    //Intercept Submit button in order to make ajax call instead of a postback
    $('.umbraco-forms-form.newsletterpopup form').preventDoubleSubmission();
});
// jQuery plugin to prevent double submission of forms
jQuery.fn.preventDoubleSubmission = function () {
    $(this).on('submit', function (e) {
        e.preventDefault();

        var $form = $(this);

        if ($form.data('submitted') === true) {
            // Previously submitted - don't submit again
        } else {
            if ($form.valid()) {
                // Mark it so that the next submit can be ignored 
                $form.data('submitted', true);

                // Make ajax call form submission
                $.ajax({
                    url: $form.attr('action'),
                    type: 'POST',
                    cache: false,
                    data: new FormData(this),
                    processData: false,
                    contentType: false,
                    success: function (result) { 
                      
                        $.cookie('initialPopup', 'yes', {
                            expires:  36500 , path: '/'
                    });
                        var thankYouMessage = $(result).find('.umbraco-forms-submitmessage').first();
                        //Handles edge case where Recaptcha wasn't checked
                        $(".newsletter-popup__title").hide();
                        $(".newsletter-popup__text > p").hide();
                        if (thankYouMessage.length == 0) {
                            $(result).find('.field-validation-error').each(function (i, v) {
                                window.alert($(v).text());
                            });

                            $form.data('submitted', false);
                        }
                        else {
                            $form.html(thankYouMessage);
                        }
                    }
                });
            }
        }
    });

    // Keep chainability
    return this;
};

$(function () {

    // Add class if edge
    if (/Edge/.test(navigator.userAgent)) {
        $('body').addClass('ie ie--edge');
    }

    // Add class if IE 11
    var is_ie11 = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
    if (is_ie11) {
        
        $('body').addClass('ie ie--11');
    }

    // Add class if IE10
    var is_ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
    if (is_ie10) {
        $('body').addClass('ie ie--10');
    }

    // Add class if safari
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
        $('body').addClass('body--safari');
    }

    // Remove empty paragraphs
    $('p').filter(function(){ return $.trim(this.innerHTML)==="&nbsp;" }).remove();

    /* ==== init ==== */
    $('.lazy-load').Lazy({
        effect: 'fadeIn',
        effectTime: 300
    });

    /* === Add aria labels to owl carousel buttons === */ 
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });



    $(".no-link").click(function (ev) {
        ev.preventDefault();
    });



    $('#52592365-de6d-4b21-8d44-9b8c565d385f').on("change", function () {
        var parent = $(this).parent();
        var value = $(this).val();
        var filename = value.replace(/C:\\fakepath\\/i, '')
        console.log("VALUE: %o", filename);
        $(".file-upload__confirm").remove();
        parent.append("<span class='file-upload__confirm'> Chosen file: " + filename + " </span>");

       // alert("CLICKED");
    });
    /* === Custom bullet list === */
    $('.list-item--custom').closest('ul').addClass('ul--stripped');


    //$('.map-panel').lazyLoadGoogleMaps();


    /* ==== Cookie notice ==== */
   
    var cookie = $('.cookie-notice');
  
    if (Cookies.get('northCookie')) {
        cookie.hide();
    } else {
        cookie.show();
        $('.cookie-notice__close').click(function () {
            Cookies.set('northCookie', 'accepted', {
                expires: 365
            });
            cookie.hide();
        });
    }
});

$(window).on('load', function () {
    var jobRole = $("#jobRole").val();
    var jobRef = $("#jobRef").val();
    $("#1c9a869f-121f-4e84-e64a-a70ea8abba58").val(jobRef);
    $("#24e14ec2-7255-4cfd-d11e-2083ac8e9b2f").val(jobRole);
    
    // Remove loading window
    setTimeout(function () {
        $('.loading-overlay').addClass('loading-overlay--hide');
        $('.homepage').addClass('homepage--active');
        $('main').addClass('main--active');

        // Re-run the polyfill on multiple elements
        var elements = document.querySelectorAll('.main-banner__img');
        objectFitPolyfill(elements);

        elements2 = document.querySelectorAll('.panel__image');
        objectFitPolyfill(elements2);

    }, 500);

});

