$(function () {
    search_trigger = $('.header__search-trigger');
    search_box = $('.header__search-form');

    search_trigger.click(function () {
        $(this).toggleClass('search-trigger--active');
        search_box.toggleClass('header__search-form--active');
        // body.toggleClass('body--overlay');
    });

    search_box.click(function (event) {
        event.stopPropagation();
    });

    $(document).click(function (e) {
        if (!$(search_trigger, search_box).is(e.target)) {
            search_trigger.removeClass('header__search-trigger--active');
            search_box.removeClass('header__search-form--active');
            if ($('body').hasClass('body--overlay')) {
                $('body').removeClass('body--overlay');
            }
        }
    });
});